// Generated by Framer (d1ef57f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["WGDpedVxg"];

const serializationHash = "framer-oCS8b"

const variantClassNames = {WGDpedVxg: "framer-v-ahxymu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, cyruJVgZh: title ?? props.cyruJVgZh ?? "Label"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, cyruJVgZh, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WGDpedVxg", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-oCS8b", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-ahxymu", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WGDpedVxg"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-d1a8a707-d8d2-43ea-b7b4-2e5c182799d0, rgb(10, 220, 129))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter-Medium\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-line-height": "1em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-6af95ce3-cc10-4d84-a3c9-9843318a79a2, rgb(255, 255, 255)))", "--framer-text-transform": "uppercase"}}>Label</motion.p></React.Fragment>} className={"framer-do644l"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"ykJBoAaR9"} style={{"--extracted-r6o4lv": "var(--token-6af95ce3-cc10-4d84-a3c9-9843318a79a2, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={cyruJVgZh} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-oCS8b [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oCS8b .framer-13bzy5b { display: block; }", ".framer-oCS8b .framer-ahxymu { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 4px 10px 4px 10px; position: relative; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-oCS8b .framer-do644l { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-oCS8b .framer-ahxymu { gap: 0px; } .framer-oCS8b .framer-ahxymu > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-oCS8b .framer-ahxymu > :first-child { margin-left: 0px; } .framer-oCS8b .framer-ahxymu > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 63
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"cyruJVgZh":"title"}
 * @framerImmutableVariables true
 */
const FramerAKyu0dRqa: React.ComponentType<Props> = withCSS(Component, css, "framer-oCS8b") as typeof Component;
export default FramerAKyu0dRqa;

FramerAKyu0dRqa.displayName = "Label";

FramerAKyu0dRqa.defaultProps = {height: 22, width: 63};

addPropertyControls(FramerAKyu0dRqa, {cyruJVgZh: {defaultValue: "Label", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerAKyu0dRqa, [])